import { A11yModule } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { ButtonDirective } from './button.directive';
import { NgIconComponent } from '@ng-icons/core';
import { CardModule } from './card';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lib-ble-connect-btn',
  standalone: true,
  imports: [CardModule, ButtonDirective, A11yModule, NgIconComponent, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @if (isBluetoothAvailable()) {
      <div class="flex flex-row">
        <button
          *ngIf="
            manyBleDeviceConnectedAllowed() || (!isAtLeastOneBleDeviceConnected() && !manyBleDeviceConnectedAllowed())
          "
          libBtn
          variant="outline"
          class="text-pacificBlue-900 border-pacificBlue-900"
          size="xxs"
          (click)="bluetoothConnect($event)">
          <ng-icon name="iconoirBluetoothTag" class="mr-2" size="20px" />
          <span>Bluetooth connect</span>
        </button>

        <button
          *ngIf="isAtLeastOneBleDeviceConnected()"
          libBtn
          [class]="manyBleDeviceConnectedAllowed() ? 'ml-2' : ''"
          variant="outline"
          class="border-red-500 text-red-500"
          size="xxs"
          (click)="disconnectAll('all')">
          <ng-icon name="iconoirBluetoothTag" class="mr-2" size="20px" />
          <span
            >Disconnect
            @if (manyBleDeviceConnectedAllowed()) {
              all
            }
          </span>
        </button>
      </div>
    }
  `,
  host: {
    class: 'block',
  },
})
export class BluetoothConnectBtnComponent {
  isBluetoothAvailable = input<boolean>(false);
  isAtLeastOneBleDeviceConnected = input<boolean>(false);
  manyBleDeviceConnectedAllowed = input<boolean>(true);
  bluetoothConnectEvent = output<() => void>();
  disconnectBluetoothEvent = output<string>();
  bluetoothConnect($event: any) {
    this.bluetoothConnectEvent.emit($event);
  }

  disconnectAll(id: string) {
    this.disconnectBluetoothEvent.emit(id);
  }
}
