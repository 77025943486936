import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { NgForOf, NgIf } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';

export interface ColumnDef {
  field: string;
  header: string;
  sortable?: boolean;
  width?: string;
}

@Component({
  selector: 'lib-generic-table',
  template: `
    <p-table
      paginatorDropdownAppendTo="body"
      class="w-full text-left text-sm text-gray-600"
      [value]="loading ? [] : data"
      [paginator]="paginator"
      [lazy]="lazy"
      [totalRecords]="totalRecords"
      [rows]="rows"
      [rowsPerPageOptions]="rowsPerPageOptions"
      [first]="first"
      [loading]="loading"
      [showLoader]="false"
      [sortField]="sortField"
      [sortOrder]="sortOrder"
      (onLazyLoad)="lazyLoad.emit($event)"
      [(selection)]="selection"
      (selectionChange)="selectionChange.emit($event)"
      [currentPageReportTemplate]="currentPageReportTemplate"
      [showCurrentPageReport]="showCurrentPageReport"
      [scrollable]="scrollable"
      [scrollHeight]="scrollHeight">
      <ng-template #header>
        <tr class="border-b bg-white text-xs uppercase text-black">
          <th *ngIf="selectionMode">
            <p-tableHeaderCheckbox *ngIf="selectionMode === 'multiple'"></p-tableHeaderCheckbox>
          </th>
          <th
            *ngFor="let col of columns"
            [pSortableColumn]="col.sortable ? col.field : undefined"
            [style.width]="col.width">
            {{ col.header }}
            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template #body let-rowData>
        <tr>
          <td *ngIf="selectionMode">
            <p-tableCheckbox *ngIf="selectionMode === 'multiple'" [value]="rowData"></p-tableCheckbox>
          </td>
          <td *ngFor="let col of columns" [style.width]="col.width">
            {{ getFieldValue(rowData, col.field) }}
          </td>
        </tr>
      </ng-template>

      <ng-template #loadingbody>
        <tr *ngFor="let _ of loadingSkeletonRows">
          <td *ngIf="selectionMode">
            <p-skeleton></p-skeleton>
          </td>
          <td *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </td>
        </tr>
      </ng-template>

      <ng-template #emptymessage>
        <tr>
          <td [attr.colspan]="columns.length + (selectionMode ? 1 : 0)" class="py-8 text-center">No Data found.</td>
        </tr>
      </ng-template>
    </p-table>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  host: {
    class: 'block w-full overflow-x-auto overflow-y-hidden rounded-xl border',
  },
  styles: `
    @media (max-width: 768px) {
      .p-datatable .p-datatable-thead > tr > th,
      .p-datatable .p-datatable-tbody > tr > td {
        padding: 0.5rem;
      }
    }
  `,
  imports: [TableModule, NgIf, NgForOf, SkeletonModule],
})
export class GenericTableComponent<T> {
  @Input() data: T[] = [];
  @Input() columns: ColumnDef[] = [];
  @Input() totalRecords = 0;
  @Input() paginator = true;
  @Input() lazy = true;
  @Input() rows = 10;
  @Input() rowsPerPageOptions: number[] = [10, 20];
  @Input() first = 0;
  @Input() loading = false;
  @Input() sortField = '';
  @Input() sortOrder = 1;
  @Input() selectionMode: 'single' | 'multiple' | null = null;
  @Input() selection: T[] = [];
  @Output() selectionChange = new EventEmitter<T[]>();
  @Input() scrollable = true;
  @Input() scrollHeight = 'flex';
  @Input() currentPageReportTemplate = 'Total Items: {totalRecords}';
  @Input() showCurrentPageReport = true;

  @Output() lazyLoad = new EventEmitter<TableLazyLoadEvent>();

  get loadingSkeletonRows() {
    return Array.from({ length: this.rows });
  }

  // Helper method to handle nested fields
  getFieldValue(rowData: any, field: string): any {
    return field.split('.').reduce((acc, curr) => (acc ? acc[curr] : null), rowData);
  }
}
